import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import { me } from './redux/slices/auth.slice';
import Bids from './pages/bids';
import Games from './pages/games';
import Teams from './pages/teams';
import MainLayout from './layouts/main.layout';
import BasicLayout from './layouts/basic.layout';
import './App.css';
import LoginPage from './pages/login';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if (auth.data) {
      setLoggedIn(true);
    } else {
      dispatch(me());
    }
  }, [])

  useEffect(() => {
    if (auth.data) {
      setLoggedIn(true);
    }
  }, [auth.data])

  const router = loggedIn ? createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Games />,
        },
        {
          path: '/teams',
          element: <Teams />,
        },
        {
          path: '/bids',
          element: <Bids />,
        },
        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
  ]) : createBrowserRouter([{
    path: '/',
    element: <BasicLayout />,
    children: [
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: "/",
        element: <Navigate to="/login" replace />
      },
      {
        path: "*",
        element: <Navigate to="/login" replace />
      }
    ]
  }
  ])

  return <RouterProvider router={router} />;
}

export default App;
