import React, { useState, useEffect } from 'react';
import { Table, Col, Row, Input, Divider, Button, message, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import {createBid, deleteBid, getBidsList} from '../redux/slices/bids.slice';
import './bids.css';

export default function Bids() {
  const dispatch = useDispatch();
  const bids = useSelector((state) => state.bids);
  const [messageApi, contextHolder] = message.useMessage();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!bids.status) {
      return;
    }

    if (bids.status === 'success') {
      messageApi.success(`Успешно!`);
    }

    if (bids.status === 'failed') {
      messageApi.error(bids?.error?.message || 'Something went wrong!');
    }

  }, [bids.status]);

  useEffect(() => {
    dispatch(getBidsList());
  }, [])

  const handleCreate = async () => {
    dispatch(createBid({ text: inputValue }));
    setInputValue('');
  };
  const handleDelete = async (uuid) => {
    dispatch(deleteBid(uuid));
  }
  const handleInputChange = (e) => {
    const { value } = e.target;

    setInputValue(value);
  }

  return (
    <>
      {contextHolder}
      <Row className="create-bid">
        <Col span={24}>
          <Input size="large" placeholder="текст" onChange={handleInputChange} value={inputValue} />
        </Col>
      </Row>
      <Row>
        <Col span={24} type="flex" align="end">
          <Button type="primary" loading={bids.createLoading} onClick={handleCreate}>
            Сохранить
          </Button>
          <Button type="secondary" loading={false} onClick={() => setInputValue('')}>
            Очистить
          </Button>
        </Col>
      </Row>
      <Divider dashed />
      <Row>
        <Col span={24}>
          <Table
              pagination={{ pageSize: 10 }}
              scroll={{ y: 400 }}
              columns={[
                {
                  title: 'Текст',
                  dataIndex: 'text',
                  key: 'text',
                  width: '70%',
                },
                {
                  title: 'Действия',
                  dataIndex: 'uuid',
                  key: 'actions',
                  width: '30%',
                  render: (uuid) => <Popconfirm
                      title="Удалить ставку?"
                      onConfirm={() => handleDelete(uuid)}
                      okText="Да"
                      cancelText="Нет"
                  >
                    <Button danger icon={<DeleteOutlined />}> Удалить </Button>
                  </Popconfirm>,
                },
              ]}
              loading={bids.loading}
              dataSource={bids.data || []}
              size={'small'}
              rowKey="uuid"
          />
        </Col>
      </Row>
    </>
  );
}
