import axios from '../utils/axios';

class GameService {
    async createGame(data) {
        const res = await axios.post('/api/games', data);

        return res.data;
    }

    async getGames() {
        const res = await axios.get('/api/games');

        return res.data;
    }

    async deleteGame(uuid) {
        await axios.delete(`/api/games/${uuid}`);

        return { uuid };
    }
}

export default new GameService();