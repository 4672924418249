import React, { useState } from 'react';
import { Modal, Button, Input, Form } from 'antd';
import UploadLogo from './upload-logo';
import {createTeam} from '../redux/slices/teams.slice';
import { useDispatch, useSelector } from 'react-redux';

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

export default function CreateTeamComponent(props) {
    const showModal = () => {
        setOpen(true);
    };
    const [open, setOpen] = useState(false);
    const [uploadImage, setUploadImage] = useState(null);
    const [teamName, setTeamName] = useState('');
    const [teamCountry, setTeamCountry] = useState('');
    const dispatch = useDispatch();

    const teams = useSelector((state) => state.teams);

    const createTeamHandler = () => {
        const formData = new FormData();
        formData.append('name', teamName);
        formData.append('country', teamCountry);
        formData.append('logo', uploadImage);

        dispatch(createTeam(formData));

        setTeamName('');
        setTeamCountry('');
        setUploadImage(null);
        setOpen(false);
    }

    const onCloseModal = () => {
        setTeamName('');
        setTeamCountry('');
        setUploadImage(null);
        setOpen(false);
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Создать команду
            </Button>
            <Modal
                title="Создать команду"
                open={open}
                onOk={createTeamHandler}
                confirmLoading={teams.createLoading}
                onCancel={onCloseModal}
                destroyOnClose={true}
            >
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 14,
                    }}
                    layout="horizontal"
                    style={{
                        maxWidth: 600,
                    }}
                >

                    <Form.Item label="Имя">
                        <Input onChange={e => setTeamName(e.target.value)} value={teamName} />
                    </Form.Item>
                    <Form.Item label="Страна">
                        <Input onChange={e => setTeamCountry(e.target.value)} value={teamCountry} />
                    </Form.Item>
                    <Form.Item label="Лого" valuePropName="fileList" getValueFromEvent={normFile}>
                        <UploadLogo setUploadImage={setUploadImage} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}