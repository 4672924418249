import React, { useEffect } from 'react';
import moment from 'moment';
import {Row, Col, message, Image, Popconfirm, Button, Table, Space, Tag} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {DeleteOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';

import {getTeams} from '../redux/slices/teams.slice';
import {getBidsList} from '../redux/slices/bids.slice';
import { getGames, deleteGame } from '../redux/slices/games.slice';
import CreateGameComponent from '../components/create-game.component';

import './games.css';

export default function Games() {
  const [messageApi, contextHolder] = message.useMessage();
  const teams = useSelector(state => state.teams);
  const bids = useSelector(state => state.bids);
  const games = useSelector(state => state.games);

  const dispatch = useDispatch();

  useEffect(() => {
    if (teams.data.length === 0) {
      dispatch(getTeams());

    }

    if (bids.data.length === 0) {
      dispatch(getBidsList());
    }

    if (games.data.length === 0) {
      dispatch(getGames());
    }
  }, [])

  useEffect(() => {
    if (!games.status) {
      return;
    }

    if (games.status === 'success') {
      messageApi.success(`Успешно!`);
    }

    if (games.status === 'failed') {
      messageApi.error(games?.error?.message || 'Something went wrong!');
    }

  }, [games.status]);

  const handleDelete = (uuid) => {
    dispatch(deleteGame(uuid));
  }

  return <>
    {contextHolder}
    <Row className={'create-game'}>
      <Col span={24}>
        <CreateGameComponent teams={teams.data} bids={bids.data}/>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Table
            pagination={{ pageSize: 10 }}
            scroll={{ y: 400 }}
            columns={[
              {
                title: 'Игра',
                key: 'uuid',
                width: '50%',
                render: (record) => {

                  return (
                      <Space direction="horizontal">
                        {record.teamA.name}
                        <Image width={30} preview={false} src={`${process.env.REACT_APP_API_STATIC_URL}/uploads/${record.teamA.logo}`} />
                        <RightOutlined />
                        <Tag>
                          {record.bid.text}
                        </Tag>
                        <LeftOutlined />
                        <Image width={30} preview={false} src={`${process.env.REACT_APP_API_STATIC_URL}/uploads/${record.teamB.logo}`} />
                        {record.teamB.name}
                      </Space>
                  );
                }
              },
              {
                title: 'Лига',
                key: 'league',
                dataIndex: 'league',
                width: '20%',
              },
              {
                title: 'Начало матча',
                key: 'startDate',
                dataIndex: 'startDate',
                width: '20%',
                render: (startDate) => (<>{moment(startDate).format('YYYY-MM-DD HH:mm')}</>)
              },
              {
                title: 'Действия',
                dataIndex: 'uuid',
                key: 'actions',
                width: '10%',
                render: (uuid) => <Popconfirm
                    title="Удалить игру?"
                    onConfirm={() => handleDelete(uuid)}
                    okText="Да"
                    cancelText="Нет"
                    loading={games.deleteLoading}
                >
                  <Button danger icon={<DeleteOutlined />}> Удалить </Button>
                </Popconfirm>,
              },
            ]}
            loading={games.loading}
            dataSource={games.data || []}
            rowKey="uuid"
            size={'small'}
        />
      </Col>
    </Row>
  </>;
}
