import React from 'react';
import {Outlet} from 'react-router-dom';
import {Layout, Flex, theme} from 'antd';

const { Header, Content } = Layout;

export default function BasicLayout() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Flex gap="middle" wrap>
            <Layout >
                <Content style={{
                    margin: '15px 10px',
                    padding: 18,
                    height: '95vh',
                    minHeight: 280,
                    background: colorBgContainer,
                }}>
                    <Outlet />
                </Content>
            </Layout>

        </Flex>
    )
}