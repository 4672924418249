import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TeamsService from '../../services/teams.service';

export const createTeam =
    createAsyncThunk(
        'teams/create',
        async (data, { rejectWithValue }
        ) => {
            try {
                return await TeamsService.createTeam(data);
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

export const getTeams =
    createAsyncThunk(
        'teams',
        async (undefined, { rejectWithValue }
        ) => {
            try {
                return await TeamsService.getTeams();
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

export const deleteTeam =
    createAsyncThunk(
        'teams/delete',
        async (uuid, { rejectWithValue }
        ) => {
            try {
                return await TeamsService.deleteTeam(uuid);
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

export const teamsSlice = createSlice({
    name: 'teams',
    initialState: {
        data: [],
        error: null,
        loading: false,
        createLoading: false,
        deleteLoading: false,
        status: '',
    },
    extraReducers: (builder) => {
        builder
            .addCase(createTeam.pending, (state, action) => {
                state.createLoading = true;
                state.status = 'pending';
            })
            .addCase(createTeam.fulfilled, (state, action) => {
                state.createLoading = false;
                state.data = [action.payload, ...state.data];
                state.status = 'success';
            })
            .addCase(createTeam.rejected, (state, action) => {
                state.createLoading = false;
                state.error = action.payload;
                state.status = 'failed';
            })
            .addCase(getTeams.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(getTeams.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getTeams.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteTeam.fulfilled, (state, action) => {
                state.status = 'success';
                state.deleteLoading = false;
                state.data = state.data.filter((item) => item.uuid !== action.payload.uuid);
            })
            .addCase(deleteTeam.rejected, (state, action) => {
                state.deleteLoading = false;
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteTeam.pending, (state, action) => {
                state.deleteLoading = true;
                state.status = 'pending';
            })
    }
})

export default teamsSlice.reducer;