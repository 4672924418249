import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../../services/auth.service';

export const login =
    createAsyncThunk(
        'auth/login',
        async (data, { rejectWithValue }
        ) => {
            try {
                return await AuthService.login(data);
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

export const me =
    createAsyncThunk(
        'auth/me',
        async (unknown, { rejectWithValue }
        ) => {
            try {
                return await AuthService.me();
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        data: null,
        error: null,
        status: '',
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.status = 'success';
        })
            .addCase(login.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.status = 'failed';
            })
            .addCase(login.pending, (state, action) => {
                state.loading = true;
                state.status = 'pending';
            })
            .addCase(me.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(me.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(me.pending, (state, action) => {
                state.loading = true;
            })
    }
})

export default authSlice.reducer;