import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BidsService from '../../services/bids.service';

export const createBid =
    createAsyncThunk(
        'bids/create',
        async (data, { rejectWithValue }
    ) => {
  try {
    return await BidsService.createBid(data);
  } catch (e) {
    return rejectWithValue(e.data);
  }
});

export const getBidsList =
    createAsyncThunk(
        'bids',
        async (undefined, { rejectWithValue }
        ) => {
          try {
            return await BidsService.getList();
          } catch (e) {
            return rejectWithValue(e.data);
          }
        });

export const deleteBid =
    createAsyncThunk(
        'bids/delete',
        async (uuid, { rejectWithValue }
        ) => {
          try {
            return await BidsService.deleteBid(uuid);
          } catch (e) {
            return rejectWithValue(e.data);
          }
        });

export const bidsSlice = createSlice({
  name: 'bids',
  initialState: {
    createLoading: false,
    deleteLoading: false,
    loading: false,
    status: '',
    error: null,
    data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBid.pending, (state) => {
        state.createLoading = true;
        state.status = 'pending';
      })
      .addCase(createBid.fulfilled, (state, action) => {
        state.createLoading = false;
        state.status = 'success';
        state.data = [action.payload, ...state.data];
      })
      .addCase(createBid.rejected, (state, action) => {
        state.createLoading = false;
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getBidsList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getBidsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBidsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
        .addCase(deleteBid.fulfilled, (state, action) => {
          state.deleteLoading = false;
          state.status = 'success';
          state.data = state.data.filter((item) => item.uuid !== action.payload.uuid);
        })
        .addCase(deleteBid.pending, (state, action) => {
          state.deleteLoading = true;
          state.status = 'pending';
        })
        .addCase(deleteBid.rejected, (state, action) => {
          state.deleteLoading = false;
          state.status = 'failed';
          state.error = action.payload;
        })
  },
});

export default bidsSlice.reducer;
