import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, message, Popconfirm, Table, Image} from 'antd';
import CreateTeamComponent from '../components/create-team.component';
import {DeleteOutlined} from '@ant-design/icons';
import {getTeams, deleteTeam} from '../redux/slices/teams.slice';
import  './teams.css';

export default function Teams() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const teams = useSelector((state) => state.teams);

    useEffect(() => {
        if (!teams.status) {
            return;
        }

        if (teams.status === 'success') {
            messageApi.success(`Успешно!`);
        }

        if (teams.status === 'failed') {
            messageApi.error(teams?.error?.message || 'Something went wrong!');
        }

    }, [teams.status]);

    useEffect(() => {
        dispatch(getTeams())
    }, []);

    const handleDelete = async (uuid) => {
        dispatch(deleteTeam(uuid))
    }

  return (
      <>
          {contextHolder}
        <CreateTeamComponent />
          <Table
              pagination={{ pageSize: 10 }}
              scroll={{ y: 400 }}
              columns={[
                  {
                      title: 'Команда',
                      dataIndex: 'name',
                      key: 'name',
                      width: '30%',
                  },
                  {
                      title: 'Страна',
                      dataIndex: 'country',
                      key: 'country',
                      width: '30%',
                  },
                  {
                      title: 'Лого',
                      dataIndex: 'logo',
                      key: 'logo',
                      width: '30%',
                      render: (logo) => <Image
                          width={30}
                          src={`${process.env.REACT_APP_API_STATIC_URL}/uploads/${logo}`}
                      />
                  },
                  {
                      title: 'Действия',
                      dataIndex: 'uuid',
                      key: 'actions',
                      width: '10%',
                      render: (uuid) => <Popconfirm
                          title="Удалить команду?"
                          onConfirm={() => handleDelete(uuid)}
                          okText="Да"
                          cancelText="Нет"
                          loading={teams.deleteLoading}
                      >
                          <Button danger icon={<DeleteOutlined />}> Удалить </Button>
                      </Popconfirm>,
                  },
              ]}
              loading={teams.loading}
              dataSource={teams.data || []}
              size={'small'}
              rowKey="uuid"
          />
      </>
  );
}
