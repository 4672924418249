import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConfigProvider
      // theme={{
      //   token: {
      //     // // Seed Token
      //     // colorPrimary: '#00b96b',
      //     // borderRadius: 6,
      //     //
      //     // // Alias Token
      //     // colorBgContainer: '#f6ffed',
      //     colorTextBase: '#4c4e4e',
      //     // colorPrimary: '#002b36',
      //     // colorInfo: '#002b36',
      //     // colorSuccess: '#002b36',
      //       colorSuccess: "green",
      //       colorError: "red",
      //       colorInfo: "blue",
      //       colorWarning: "yellow",
      //   },
      // }}
    >
      <App />
    </ConfigProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
