import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GameService from '../../services/game.service';

export const createGame =
    createAsyncThunk(
        'games/create',
        async (data, { rejectWithValue }
        ) => {
            try {
                return await GameService.createGame(data);
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

export const getGames =
    createAsyncThunk(
        'games',
        async (undefined, { rejectWithValue }
        ) => {
            try {
                return await GameService.getGames();
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

export const deleteGame =
    createAsyncThunk(
        'games/delete',
        async (uuid, { rejectWithValue }
        ) => {
            try {
                return await GameService.deleteGame(uuid);
            } catch (e) {
                return rejectWithValue(e.data);
            }
        });

export const gamesSlice = createSlice({
    name: 'games',
    initialState: {
        data: [],
        loading: false,
        createLoading: false,
        deleteLoading: false,
        status: '',
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(createGame.fulfilled, (state, action) => {
            state.data = [action.payload, ...state.data];
            state.createLoading = false;
            state.status = 'success';
        })
            .addCase(createGame.pending, (state, action) => {
                state.createLoading = true;
                state.status = 'pending';
            })
            .addCase(createGame.rejected, (state, action) => {
                state.error = action.payload;
                state.status = 'failed';
                state.createLoading = false;
            })
            .addCase(getGames.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(getGames.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getGames.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteGame.fulfilled, (state, action) => {
                state.status = 'success';
                state.deleteLoading = false;
                state.data = state.data.filter((item) => item.uuid !== action.payload.uuid);
            })
            .addCase(deleteGame.rejected, (state, action) => {
                state.error = action.payload;
                state.status = 'failed';
                state.deleteLoading = false;
            })
            .addCase(deleteGame.pending, (state, action) => {
                state.status = 'pending';
                state.deleteLoading = true;
            })
    }
});

export default gamesSlice.reducer;