import React, {useEffect, useState} from 'react';
import {Form, Input, Button, message, Row} from 'antd';
import {UnlockOutlined, UserOutlined,} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/slices/auth.slice';

import './login.css';

export default function LoginPage() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const auth = useSelector((state) => state.auth);


    const onLogin = async () => {
        dispatch(login({
            email,
            password,
        }))
    }

    useEffect(() => {
        if (!auth.status) {
            return;
        }

        if (auth.status === 'success') {
            messageApi.success(`Успешно!`);
        }

        if (auth.status === 'failed') {
            messageApi.error(auth?.error?.message || 'Something went wrong!');
        }

    }, [auth.status]);

    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '90vh'}}>
                {contextHolder}
                <div className={'login-form'}>
                    <Form
                        name="login"
                        style={{ maxWidth: 600 }}
                        autoComplete="off"
                    >
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input placeholder="Email" size={'large'} prefix={<UserOutlined />} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Item>

                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" size={'large'} prefix={<UnlockOutlined />} onChange={e => setPassword(e.target.value)} />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" ghost onClick={onLogin} loading={auth.loading}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
        </Row>
    )
}