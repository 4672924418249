import { configureStore } from '@reduxjs/toolkit';
import bidsSlice from './slices/bids.slice';
import teamsSlice from './slices/teams.slice';
import gamesSlice from './slices/games.slice';
import authSlice from './slices/auth.slice';

export default configureStore({
  reducer: {
    bids: bidsSlice,
    teams: teamsSlice,
    games: gamesSlice,
    auth: authSlice,
  },
});
