import axios from '../utils/axios';

class AuthService {
    async login(data) {
        const res = await axios.post('/api/auth/login', data);

        localStorage.setItem('token', res.data.token);
        localStorage.setItem('refreshToken', res.data.refreshToken);

        return res.data;
    }

    async me() {
        const res = await axios.post('/api/auth/me');

        return res.data;
    }

    async refresh() {
        const res = await axios.post('/api/auth/refresh');

        localStorage.setItem('token', res.data.token);
        localStorage.setItem('refreshToken', res.data.refreshToken);

        return res.data;
    }
}

export default new AuthService();