import React, { useState, useEffect } from 'react';
import {Modal, Button, Input, Form, Select, DatePicker, Image, Space} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {createGame} from '../redux/slices/games.slice';


export default function CreateGameComponent(props) {
    const showModal = () => {
        setOpen(true);
    };
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const games = useSelector(state => state.games);

    const [teamAUuid, setTeamAUuid] = useState('');
    const [teamBUuid, setTeamBUuid] = useState('');
    const [bidUuid, setBidUuid] = useState('');
    const [leagueInput, setLeagueInput] = useState('');
    const [startDate, setStartDate] = useState('');

    const createGameHandler = () => {
        dispatch(createGame({
            teamAUuid,
            teamBUuid,
            bidUuid,
            startDate,
            league: leagueInput,
        }))
    }

    const onCloseModal = () => {
        setOpen(false);
        setTeamAUuid('');
        setTeamBUuid('');
        setBidUuid('');
        setStartDate('');
        setLeagueInput('');
    }

    useEffect(() => {
        if (games.status === 'success') {
            onCloseModal();
        }
    }, [games.status])

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Создать игру
            </Button>
            <Modal
                title="Создать команду"
                open={open}
                onOk={createGameHandler}
                confirmLoading={games.createLoading}
                onCancel={onCloseModal}
                destroyOnClose={true}
            >
                <Form
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    layout="horizontal"
                    style={{
                        maxWidth: 800,
                    }}
                >

                    <Form.Item label="Команда 1">
                        <Select onChange={setTeamAUuid}>
                            {
                                props.teams.map((team, i) => (
                                    <Select.Option
                                        key={team.uuid}
                                        value={team.uuid}
                                    >
                                        <Space direction="horizontal">{team.name}
                                            <Image width={20} preview={false} src={`${process.env.REACT_APP_API_STATIC_URL}/uploads/${team.logo}`} />
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="Команда 2">
                        <Select onChange={setTeamBUuid}>
                            {
                                props.teams.map((team, i) => (
                                    <Select.Option
                                        key={team.uuid}
                                        value={team.uuid}>
                                        <Space direction="horizontal">{team.name}
                                            <Image width={20} preview={false} src={`${process.env.REACT_APP_API_STATIC_URL}/uploads/${team.logo}`} />
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="Ставка">
                        <Select onChange={setBidUuid}>
                            {
                                props.bids.map((bid, i) => (
                                    <Select.Option key={bid.uuid} value={bid.uuid}>{bid.text}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="Лига">
                        <Input onChange={e => setLeagueInput(e.target.value)} value={leagueInput} />
                    </Form.Item>
                    <Form.Item label={'Начало матча'}>
                        <DatePicker
                            onChange={(date, dateString) => setStartDate(dateString)}
                            showTime
                            needConfirm={true}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}