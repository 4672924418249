import axios from 'axios';

async function refreshAccessToken(refreshToken) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/refresh`,
      {
        refreshToken,
      },
    );

    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    return response.data.token;
  } catch (e) {
      window.location.replace('/login');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    config.headers.Accept = 'application/json';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {

    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refreshToken');

    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken(refreshToken);

      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      return axiosInstance(originalRequest);
    }

    if (error.response) {
      error.data =
        error.response.status === 500
          ? { message: 'Something went wrong, please try again.' }
          : error.response.data;
    } else {
      error.data = {
        message: 'Something went wrong, please try again.',
      };
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
