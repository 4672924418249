import axios from '../utils/axios';

class TeamsService {
    async createTeam(data) {
        const res = await axios.post('/api/teams', data);

        return res.data;
    }

    async getTeams() {
        const res = await axios.get('/api/teams');

        return res.data;
    }

    async deleteTeam(uuid) {
        await axios.delete(`/api/teams/${uuid}`);

        return { uuid };
    }
}

export default new TeamsService();
