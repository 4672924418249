import axios from '../utils/axios';

class BidsService {
  async getList() {
    const res = await axios.get('/api/bids');

    return res.data;
  }

  async createBid(data) {
    const res = await axios.post('/api/bids', data);

    return res.data;
  }

  async deleteBid(uuid) {
    await axios.delete(`/api/bids/${uuid}`);

    return { uuid };
  }
}

export default new BidsService();
